<template>
  <div class="numberstrip">
    <span
      class="numberstrip__number"
      v-for="(position, i) in positions"
      :key="i"
      :style="`background-position: 0 -${position.offset * 64}px`"
      :class="{ 'numberstrip__number--inactive': !position.active }"
    >
      {{ position.char }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    number: [Number, null],
    minLength: {
      type: Number,
      default: 1
    }
  },
  computed: {
    positions() {
      if (this.number === null) {
        return Array(this.minLength).fill({
          active: false,
          char: 0,
          offset: 0
        })
      }
      const chars = Array.from(String(this.number), Number)
      const positions = chars.map((char, i) => {
        return {
          active: true,
          char,
          offset: parseFloat(
            chars
              .slice(0, i + 1)
              // using .slice(-3) to avoid very large background-position numbers
              .slice(-3)
              .join('')
          )
        }
      })
      for (let i = positions.length; i < this.minLength; i++) {
        positions.unshift({
          active: false,
          char: 0,
          offset: 0
        })
      }
      return positions
    }
  }
}
</script>

<style lang="scss" scoped>
.numberstrip {
  display: inline-block;
  padding: 0 12px;

  border: 3px #ccc solid;
  border-radius: 10px;

  &__number {
    height: 64px;
    width: 29px;
    display: inline-block;

    background: url(../../assets/numberstrip.svg) center 0;
    transition: background-position 0.5s ease;

    color: transparent;
    font-size: 48px;
    line-height: 64px;

    &--inactive {
      opacity: 0.25;
    }
  }
}
</style>
