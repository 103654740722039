<template>
  <div>
    <ListSkeleton v-if="sepaFiles === null" />
    <ul v-else-if="sepaFiles.length > 0" class="list-group">
      <li class="list-group-item" v-for="sepaFile in sortedSepaFiles" :key="sepaFile.id">
        <div class="d-flex justify-content-between align-items-center">
          <span
            class="sepa-files__handle"
            :class="{ 'sepa-files__handle--open': sepaFile.id === showDetailsId }"
            @click="showDetailsId = showDetailsId === sepaFile.id ? null : sepaFile.id"
          >
            {{ sepaFile.sepaPayments[0].accountHolder }}
            <span v-if="sepaFile.sepaPayments.length > 1">+ {{ sepaFile.sepaPayments.length - 1 }} more</span>
          </span>
          <div>
            <span>{{ sepaFile.createdAt.toDate() | date }}</span>
            <button
              class="btn btn-sm btn-outline-primary ml-3"
              @click="downloadXml(sepaFile.id)"
              :download="`${sepaFile.id}.xml`"
            >
              Download
            </button>
            <button class="btn btn-sm btn-outline-primary ml-3" @click="closeSepaFile(sepaFile.id)">Close</button>
          </div>
        </div>
        <div class="ml-2 mt-3" v-if="sepaFile.id === showDetailsId">
          <table class="table table-sm table-bordered">
            <tbody>
              <tr v-for="sepaPayment in sepaFile.sepaPayments" :key="sepaPayment.id">
                <td>{{ sepaPayment.accountHolder }}</td>
                <td width="150px">{{ sepaPayment.purpose }}</td>
                <td width="150px">{{ formatCurrency(sepaPayment.amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </li>
    </ul>
    <div class="alert alert-success" role="alert" v-else>
      All SEPA files are already processed!
    </div>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import ListSkeleton from '@/components/ListSkeleton'
import { saveAs } from 'file-saver'
import moment from 'moment'

export default {
  components: { ListSkeleton },
  data() {
    return {
      showSkeleton: false,
      sepaFiles: null,
      sepaFilesSubscription: null,
      showDetailsId: null
    }
  },
  created() {
    this.sepaFilesSubscription = db
      .collection('private/banking/sepaFiles')
      .where('transferredToBank', '==', false)
      .onSnapshot(snapshot => {
        this.sepaFiles = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
      })
  },
  beforeDestroy() {
    this.sepaFilesSubscription()
  },
  filters: {
    date(input) {
      return moment(input).format('DD.MM.')
    }
  },

  methods: {
    moment() {
      return moment()
    },
    downloadXml(sepaFileId) {
      const xml = this.sepaFiles.find(s => s.id === sepaFileId).xml
      saveAs(new Blob([xml], { type: 'application/xml;charset=utf-8' }), `${sepaFileId}.xml`)
    },
    closeSepaFile(sepaFileId) {
      db.doc(`private/banking/sepaFiles/${sepaFileId}`).update({
        transferredToBank: true
      })
    }
  },
  computed: {
    sortedSepaFiles() {
      const sortedSepaFiles = _.cloneDeep(this.sepaFiles).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
      return sortedSepaFiles
    }
  }
}
</script>

<style lang="scss" scoped>
.sepa-files {
  &__handle {
    cursor: pointer;

    &::before {
      content: '❯';
      display: inline-block;
      margin-right: 0.25rem;
    }

    &--open {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
