<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    <div v-if="$store.state.role === 'admin'" class="mt-5">
      <h2 class="h3 mb-3">Open Orders</h2>
      <OpenOrders />
    </div>
    <div v-if="$store.state.role === 'admin'" class=" mt-5">
      <h2 class="h3 mb-3">SEPA-Files</h2>
      <SepaFiles />
    </div>
  </div>
</template>

<script>
import OpenOrders from '@/components/Dashboard/OpenOrders'
import SepaFiles from '@/components/Dashboard/SepaFiles'

export default {
  components: { OpenOrders, SepaFiles },
  created() {
    if (this.$store.state.role !== 'admin') this.$router.replace('/materialshipments')
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  max-width: 80rem;
  margin: 0 auto;
}
</style>
