<template>
  <div>
    <Numberstrip :number="orderCounts.inTime" :minLength="3" class="mr-2" style="border-color: green;" />
    <Numberstrip :number="orderCounts.delayed" :minLength="3" class="mr-2" style="border-color: #e7d426;" />
    <Numberstrip :number="orderCounts.overdue" :minLength="3" style="border-color: red" />
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import moment from 'moment'
import Numberstrip from '@/components/Dashboard/Numberstrip'

export default {
  components: { Numberstrip },
  data() {
    return {
      orderCounts: { inTime: null, delayed: null, overdue: null },
      ordersSubscription: null
    }
  },
  created() {
    this.ordersSubscription = db
      .collection('orders')
      .where('state', '==', 'preparing')
      .onSnapshot(snapshot => {
        const docs = snapshot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
        this.parseOrders(docs)
      })
  },
  methods: {
    parseOrders(orders) {
      const orderCounts = { inTime: 0, delayed: 0, overdue: 0 }
      orders.forEach(order => {
        const weekdayCounter = this.calculateWorkingDays(order.createdAt.toDate())
        if (weekdayCounter === 0) orderCounts.inTime++
        if (weekdayCounter === 1) orderCounts.delayed++
        if (weekdayCounter >= 2) orderCounts.overdue++
      })
      this.orderCounts = orderCounts
    },
    calculateWorkingDays(startDate) {
      let start = moment(startDate)
      const end =
        moment(new Date()).day() === 0
          ? moment(new Date()).add(1, 'days')
          : moment(new Date()).day() === 6
          ? moment(new Date()).add(2, 'days')
          : moment(new Date())

      let weekdayCounter = 0

      while (start.format('YYYY-MM-DD') < end.format('YYYY-MM-DD')) {
        const onlyWeekdays = !['Sat', 'Sun'].includes(start.format('ddd'))
        if (onlyWeekdays) weekdayCounter++
        start = moment(start).add(1, 'days')
      }
      return weekdayCounter
    }
  },
  beforeDestroy() {
    this.ordersSubscription()
  }
}
</script>
