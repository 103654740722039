<template>
  <ul class="list-group">
    <li
      class="list-group-item d-flex justify-content-between"
      v-for="index in 5"
      :key="index"
      :style="`opacity: ${1 / index + 1}`"
      :set="(width = randomWidth())"
    >
      <div class="list-skeleton__bar" :style="`width: ${width}`" />
      <div class="list-skeleton__bar" :style="`width: calc(28rem - ${width})`" />
      <div class="list-skeleton__bar" :style="`width: ${width}`" />
    </li>
  </ul>
</template>

<script>
export default {
  methods: {
    randomWidth() {
      return Math.floor(Math.random() * 10) + 7 + 'rem'
    }
  }
}
</script>

<style lang="scss" scoped>
.list-skeleton {
  text-align: center;
  &__bar {
    height: 1rem;
    margin: 0.25rem 0;
    background: #ddd;
    display: inline-block;
  }
}
</style>
